<div class="main-container">
  <header class="home-header">
    <h1 class="logo">STESAJ</h1>
    <div class="user">
      <div class="user-info">
        <div class="contact-info">
          <mat-icon
            class="icon-notify"
            aria-hidden="false"
            aria-label="notification"
          >
            notifications
          </mat-icon>
          <div>
            <p class="user-name">{{ user?.givenName }}</p>
            <p class="user-mail">{{ user?.mail }}</p>
          </div>
        </div>
        <button mat-icon-button (click)="logout()">
          <mat-icon
            class="icon-close"
            aria-hidden="false"
            aria-label="new-element"
          >
            close
          </mat-icon>
        </button>
      </div>
      <div
        class=""
        style="display: grid; align-items: center; padding-top: 2rem"
      >
        <button
          mat-button
          style="
            background-color: #c4c4c4;
            text-decoration: none;
            color: black;
            font-size: 1.5rem;
          "
          (click)="redirectToVlex()"
        >
          <strong> vlex </strong>
        </button>
      </div>
    </div>
  </header>
  <div class="section section-mainTitle">
    <h2 class="main-title">Simulaciones</h2>
  </div>
  <div class="filters">
    <mat-form-field class="filter-search" appearance="outline">
      <mat-label>Ingrese el nombre del procedimiento</mat-label>
      <input
        matInput
        #search
        class="input-search"
        type="search"
        [(ngModel)]="searchValue"
      />
      <mat-icon matSuffix aria-hidden="false" aria-label="new-element">
        search
      </mat-icon>
    </mat-form-field>

    <mat-form-field class="filter-category" appearance="outline">
      <mat-label>Categoría</mat-label>
      <mat-select
        name="selectCategory"
        id=""
        [(ngModel)]="category_selected"
        [value]="selectCategory"
      >
        <mat-option value="0"></mat-option>
        <mat-option value="1">Todos</mat-option>
      </mat-select>
      <mat-icon matSuffix aria-hidden="false" aria-label="arrow_downward">
        arrow_downward
      </mat-icon>
    </mat-form-field>
  </div>
  <div class="section section-procceslist">
    <app-proccess-box
      *ngFor="let simulation of simulations"
      [simulation]="simulation"
      [userRol]="user.role"
    >
    </app-proccess-box>
  </div>

  <div *ngIf="user?.role == 'tutor'" class="button-float">
    <p>CREAR PROCESO</p>
    <button mat-fab (click)="abrirDialog()">
      <mat-icon class="icon-add" aria-hidden="false" aria-label="new-element"
        >add
      </mat-icon>
    </button>
  </div>
</div>
