import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { CrearProcesoComponent } from 'src/app/components/crear-proceso/crear-proceso.component';

import { AuthService, SimulationService, UserService } from 'src/app/services';
import { User } from 'src/app/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  simulations: any[] = [];
  user!: User;
  searchValue: string = '';
  //select category
  selectCategory: string = '';
  category_selected: string = '';

  // El router es importante para el sidebar
  constructor(
    public dialog: MatDialog,
    private simulationService: SimulationService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userService.userHome$.subscribe((user) => {
      this.user = user;
      if (this.user.role === 'tutor') {
        this.simulationService.getSimulations().subscribe((result) => {
          this.simulations = result;
        });
      } else {
        this.simulationService
          .getSimulationByUser(this.user.displayName!)
          .subscribe((simulations) => {
            this.simulations = simulations;
          });
      }
    });
  }

  redirectToVlex() {
    window.open(
      'https://login.vlex.com/session/auth/active-directory',
      '_blank'
    );
  }

  abrirDialog(): void {
    this.dialog.open(CrearProcesoComponent, {
      panelClass: 'dialog-process',
    });
  }

  logout() {
    this.authService.logout();
  }
}
