import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MaterialModule } from '../material/material.module';

import { CrearProcesoComponent } from './crear-proceso/crear-proceso.component';
import { DocumentInformationComponent } from './document-information/document-information.component';
import { HeaderComponent } from './header/header.component';
import { ProcessBoxComponent } from './process-box/process-box.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { CountDownComponent } from './count-down/count-down.component';

@NgModule({
  declarations: [
    CrearProcesoComponent,
    DocumentInformationComponent,
    HeaderComponent,
    ProcessBoxComponent,
    SidebarComponent,
    UploadFileComponent,
    CountDownComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [
    CrearProcesoComponent,
    DocumentInformationComponent,
    HeaderComponent,
    ProcessBoxComponent,
    SidebarComponent,
    UploadFileComponent,
    CountDownComponent,
  ],
})
export class ComponentsModule {}
