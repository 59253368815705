import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Commit } from 'src/app/models/commit';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommitService {

  constructor(private readonly db: AngularFirestore) {}

  getAllCommit(){
    return this.db.collection<Commit>('commit').snapshotChanges().pipe(
      map( actions => actions.map( a=>{
        const data = a.payload.doc.data() as Commit;
        data.id = a.payload.doc.id;
        return data;
      }))
    )
  }

  getCommitsByDocument(document: string){
    return this.db.collection('commit', ref => ref.where("document","==",document)).snapshotChanges().pipe(
      map(actions => actions.map( a=> {
        const data = a.payload.doc.data() as Commit;
        data.id = a.payload.doc.id;
        return data;
      }))
    )
  }
  putCommit(commit: Commit){
    this.db.collection<Commit>('commit').add(commit)
    console.log('comentario nuevo')
  }
}
