<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav" class="sidenav">
    <app-sidebar (onCloseSidenav)="sidenav.close()" [steps]="steps"></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content>
      <div class="sidebar">
        <button
          class="float-button"
          type="button"
          mat-button
          (click)=" !home && sidenav.toggle()">
          <mat-icon>reorder</mat-icon>
        </button>
      </div>
      <div class="main-content">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>
  </mat-sidenav-content>
</mat-sidenav-container>