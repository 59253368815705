import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  email: string = "";
  password: string = "";

  constructor( private authService: AuthService,
               private router: Router ) { }

  login() {

    if (this.email.length == 0 || this.password.length == 0) {
      Swal.fire({
        title: 'Error',
        text: 'Debe ingresar un correo y contraseña',
        icon: 'error',
      })
      return;
    }

    this.authService.loginEmailPassword( this.email, this.password )
      .then( res => {
        this.router.navigateByUrl('/home');
      }).catch( error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El correo o la constraseña son incorrectos',
        })
      })
  }

  loginUtpl() {
    this.authService.loginMicrosoft()
  }

}
