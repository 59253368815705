import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DocumentService, SimulationService } from 'src/app/services';
import { StepSimulation, StepsReview } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-process-review',
  templateUrl: './process-review.component.html',
  styleUrls: ['./process-review.component.css'],
})
export class ProcessReviewComponent implements OnInit, OnDestroy {
  steps: StepSimulation[] = [];
  documents: StepsReview[] = [];
  simulationObs!: Subscription;
  displayedColumns: string[] = [
    'filename',
    'name',
    'state',
    'publication_date',
    'author',
    'actions',
  ];
  completed: boolean = false;

  constructor(
    private router: Router,
    private simulationService: SimulationService,
    private documentService: DocumentService
  ) {}
  ngOnDestroy(): void {
    this.simulationObs.unsubscribe();
  }

  ngOnInit(): void {
    const id = this.router.url.split('/')[2];

    this.simulationObs = this.simulationService
      .getSimulationByIdentifier(id)
      .pipe(take(1))
      .subscribe((result) => {
        if (!this.completed) {
          this.steps = result[0].steps || [];
          this.steps.forEach((step) => {
            if (step.document) {
              this.documentService
                .getDocumentById(step.document)
                .subscribe((result) => {
                  this.documents.push({
                    ...step,
                    author: result.author,
                    fileName: result.name,
                    publication_date: new Date(result.publication_date),
                    state: true,
                  });
                });
            } else {
              this.documents.push({
                ...step,
                state: false,
              });
            }

            if (step.document2) {
              this.documentService
                .getDocumentById(step.document2)
                .subscribe((result) => {
                  this.documents.push({
                    ...step,
                    author: result.author,
                    fileName: result.name,
                    publication_date: new Date(result.publication_date),
                    state: true,
                  });
                });
            }
          });
          setTimeout(() => {
            this.completed = true;
          }, 500);
        }
      });
  }

  viewFile(id: string) {
    if (id !== undefined) {
      this.router.navigate([`document-review/${id}`]);
    }
  }
}
