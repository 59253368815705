import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Document } from 'src/app/models';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-document-information',
  templateUrl: './document-information.component.html',
  styleUrls: ['./document-information.component.css']
})
export class DocumentInformationComponent {

  @Input() document!: Document;

  constructor( private router: Router ) { }

  viewFile(){
    if(this.document.id){
      this.router.navigate([`document-review/${this.document.id}`]);
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Ningún archivo ha sido subido todavía',
        icon: 'error'
      })
    }
  }
}
