import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { SimulationService } from '../../services/simulation.service';
import { StepSimulation } from '../../models/steps';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  home: boolean = false;
  steps: StepSimulation[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private simulationService: SimulationService
  ) {}

  ngOnInit(): void {
    if (this.router.url !== '/home'){
      this.activatedRoute.params.subscribe( ({id}) => {
        if( id ){
          this.simulationService.getSimulationByIdentifier(id).subscribe( result => {
            this.steps = result[0].steps!;
          })
        }
      })
        
    }
  }

  onActivate(component: any) {
    if( component.router ) {
      if( component.router.url === '/home' ){
        this.home = true;
      }
    }
  }

}
