import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Simulation, StepName } from 'src/app/models';
import { SimulationService } from 'src/app/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.css'],
})
export class CountDownComponent implements OnInit, OnDestroy {
  @Input() juez: boolean = false;
  @Input() idSimulation!: string;
  @Input() step!: StepName;
  @Input() limiteDias!: number;
  @Input() secundario: boolean = false;

  @Output() fueraDeHorario = new EventEmitter<boolean>();
  fueraHorario: boolean = false;

  /* definir con numeros entre 0 y 24*/
  horarioDisponibilidad: { inicio: number; fin: number } = {
    inicio: 8,
    fin: 17,
  };
  intervalo: any;

  simulacion!: Simulation;
  dateStepStart!: Date;
  dateStepEnd!: Date;
  dateStepStop?: Date | number | string;

  disabled: boolean = false;

  nroDias!: number;

  stopped: boolean = false;
  tiempoRestante!: {
    dias: number;
    horas: number;
    minutos: number;
    segundos: number;
  };

  diasRestantes!: number;

  constructor(
    private simulationService: SimulationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.parent?.params
      .pipe(
        switchMap(({ id }) =>
          this.simulationService.getSimulationByIdentifier(id)
        )
      )
      .subscribe((result) => {
        this.simulacion = result[0];

        const stepActual = this.simulacion.steps?.find(
          (step) => step.name === this.step
        );

        this.fueraDeHorario.emit(false);
        if (this.secundario) {
          this.dateStepStart = new Date(stepActual?.start_date2!);
          this.dateStepEnd = new Date(stepActual?.end_date2!);
        } else {
          this.dateStepStart = new Date(stepActual?.start_date!);
          this.dateStepEnd = new Date(stepActual?.end_date!);
        }
        if (this.step === 'Pruebas') {
          // Voluntario
          if (!!stepActual?.document && !!stepActual.document2) {
            if (stepActual.stop_date! > stepActual.stop_date2!) {
              this.dateStepStop = stepActual?.stop_date;
            } else {
              this.dateStepStop = stepActual?.stop_date2;
            }
          }
        } else if (
          this.step === 'Apelación' ||
          this.step === 'Apelación de providencias preventivas'
        ) {
          if (stepActual?.stop_date && stepActual.stop_date2) {
            this.dateStepStop =
              stepActual.stop_date > stepActual.stop_date2
                ? stepActual.stop_date
                : stepActual.stop_date2;
          } else if (!stepActual?.stop_date) {
            this.dateStepStop = stepActual?.stop_date;
          } else if (!stepActual?.stop_date2) {
            this.dateStepStop = stepActual?.stop_date2;
          }
        } else if (this.step === 'Providencia de sustanciación') {
          if (this.secundario) {
            this.dateStepStop = stepActual?.stop_date;
          } else {
            this.dateStepStop = stepActual?.stop_date2;
          }
        } else if (this.step === 'Argumentos de contraparte') {
          if (this.secundario) {
            this.dateStepStop = stepActual?.stop_date2;
          } else {
            this.dateStepStop = stepActual?.stop_date;
          }
        } else if (this.step === 'Auto interlocutorio sobre prueba nueva') {
          if (this.secundario && stepActual?.stop_date2) {
            this.dateStepStop = stepActual?.stop_date2;
          } else if (!this.secundario && stepActual?.stop_date) {
            this.dateStepStop = stepActual?.stop_date;
          }
        } else if (!this.secundario) {
          // console.warn('Entra aqui');
          if (stepActual?.stop_date) {
            this.stopped = true;
          }
          this.dateStepStop = stepActual?.stop_date;
        }

        console.log(stepActual);
        console.log(this.dateStepStart, 'dateStepStart');
        console.log(this.dateStepEnd, 'dateStepEnd');
        // console.log(new Date(this.dateStepStop!), 'dateStepStop');
        if (this.step === 'Pruebas') {
          // voluntario
          if (stepActual?.status === 'missing') {
            this.fueraDeHorario.emit(true);
            this.fueraHorario = true;
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
          } else {
            console.warn('entra aqui');
            this.calcularTiempoRestante(); // Calcular el tiempo restante al inicializar el componente

            if (new Date().getTime() > this.dateStepEnd.getTime()) {
              this.tiempoRestante = {
                dias: 0,
                horas: 0,
                minutos: 0,
                segundos: 0,
              };
              this.stopped = true;
            } else {
              this.intervalo = setInterval(() => {
                this.calcularTiempoRestante(); // Calcular el tiempo restante cada segundo
              }, 1000);
            }
          }
        } else if (
          this.dateStepStart.getDate() > new Date().getDate() &&
          !this.dateStepStop
        ) {
          this.fueraHorario = true;
          this.fueraDeHorario.emit(true);
        } else {
          this.fueraDeHorario.emit(false);
          this.fueraHorario = false;

          if (
            (this.step === 'Apelación' ||
              this.step === 'Apelación de providencias preventivas') &&
            stepActual?.stop_date &&
            stepActual?.stop_date2
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (
            this.step === 'Providencia de sustanciación' &&
            stepActual?.stop_date2 &&
            !this.secundario
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (
            this.step === 'Providencia de sustanciación' &&
            stepActual?.stop_date &&
            this.secundario
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (
            this.step === 'Argumentos de contraparte' &&
            stepActual?.stop_date &&
            !this.secundario
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (
            this.step === 'Argumentos de contraparte' &&
            stepActual?.stop_date2 &&
            this.secundario
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (
            this.step === 'Auto interlocutorio sobre prueba nueva' &&
            stepActual?.stop_date &&
            !this.secundario
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (
            this.step === 'Auto interlocutorio sobre prueba nueva' &&
            stepActual?.stop_date2 &&
            this.secundario
          ) {
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else if (stepActual?.stop_date && !this.secundario) {
            console.warn('entra aqui');
            this.calcularTiempoDetenido(
              new Date(this.dateStepStop!),
              this.dateStepEnd
            );
            this.stopped = true;
            this.fueraHorario = false;
          } else {
            this.calcularTiempoRestante(); // Calcular el tiempo restante al inicializar el componente

            if (new Date().getTime() > this.dateStepEnd.getTime()) {
              this.tiempoRestante = {
                dias: 0,
                horas: 0,
                minutos: 0,
                segundos: 0,
              };
              this.stopped = true;
            } else {
              this.intervalo = setInterval(() => {
                this.calcularTiempoRestante(); // Calcular el tiempo restante cada segundo
              }, 1000);
            }
          }
        }
      });
  }

  calcularTiempoDetenido(dateStop: Date, dateEnd: Date) {
    if (dateStop.getTime() > dateEnd.getTime()) {
      this.tiempoRestante = {
        dias: 0,
        horas: 0,
        minutos: 0,
        segundos: 0,
      };
    } else {
      // let tiempoRestanteMS = dateEnd.getTime() - dateStop.getTime();
      // console.log(tiempoRestanteMS, 'tiempoRestanteMS');

      let dias = Math.floor(
        (dateEnd.getTime() - dateStop.getTime()) / (1000 * 60 * 60 * 24)
      );
      /* let horas = Math.floor(
        (tiempoRestanteMS % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ); */
      let horas = this.horarioDisponibilidad.fin - dateStop.getHours() - 1;
      /* let minutos = Math.floor(
        (tiempoRestanteMS % (1000 * 60 * 60)) / (1000 * 60)
      ); */
      let minutos = 59 - dateStop.getMinutes();
      // let segundos = Math.floor((tiempoRestanteMS % (1000 * 60)) / 1000);
      let segundos = 59 - dateStop.getSeconds();
      this.tiempoRestante = {
        dias: dias,
        horas: horas,
        minutos: minutos,
        segundos: segundos,
      };
    }
  }

  calcularTiempoRestante() {
    let now = new Date();

    let hoyInicio = new Date();
    hoyInicio.setHours(this.horarioDisponibilidad.inicio);
    hoyInicio.setMinutes(0);
    hoyInicio.setSeconds(0);

    let hoyFin = new Date();
    hoyFin.setHours(this.horarioDisponibilidad.fin - 1);
    hoyFin.setMinutes(59);
    hoyFin.setSeconds(59);

    if (this.dateStepStop) {
      this.calcularTiempoDetenido(
        new Date(this.dateStepStop!),
        this.dateStepEnd
      );
      this.fueraHorario = false;
    } else if (now >= hoyInicio && now < hoyFin) {
      this.fueraHorario = false;
      let tiempoRestanteMS = hoyFin.getTime() - now.getTime();

      if (tiempoRestanteMS > 0) {
        let dias = Math.floor(
          (this.dateStepEnd.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
        );
        let horas = Math.floor(
          (tiempoRestanteMS % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutos = Math.floor(
          (tiempoRestanteMS % (1000 * 60 * 60)) / (1000 * 60)
        );
        let segundos = Math.floor((tiempoRestanteMS % (1000 * 60)) / 1000);
        this.tiempoRestante = {
          dias: dias,
          horas: horas,
          minutos: minutos,
          segundos: segundos,
        };
      }
    } else {
      console.warn('fuera de horario');

      this.fueraDeHorario.emit(true);
      this.fueraHorario = true;
    }
  }

  aumentarTiempo() {
    const index = this.simulacion.steps?.findIndex(
      (step) => step.name === this.step
    );
    if (!this.dateStepStop && !this.secundario) {
      // console.log(new Date(this.simulacion.steps?.[index!].end_date!));
      const newDate =
        new Date(this.simulacion.steps?.[index!].end_date!).getTime() +
        24 * 60 * 60 * 1000;
      const paso = this.simulacion.steps?.[index!];
      paso!.end_date = new Date(newDate).getTime();
      this.simulacion.steps?.[index!] != paso!;
      this.simulationService.putSimulation(this.simulacion);

      // console.log(new Date(this.simulacion.steps?.[index!].end_date!));
    } else if (!this.dateStepStop && this.secundario) {
      // console.log(new Date(this.simulacion.steps?.[index!].end_date!));
      const newDate =
        new Date(this.simulacion.steps?.[index!].end_date2!).getTime() +
        24 * 60 * 60 * 1000;
      const paso = this.simulacion.steps?.[index!];
      paso!.end_date2 = new Date(newDate).getTime();
      this.simulacion.steps?.[index!] != paso!;
      this.simulationService.putSimulation(this.simulacion);

      // console.log(new Date(this.simulacion.steps?.[index!].end_date!));
    } else {
      console.log('No se puede agregar más el tiempo');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No se puede agregar más el tiempo',
      });
    }
  }

  disminuirTiempo() {
    const index = this.simulacion.steps?.findIndex(
      (step) => step.name === this.step
    );
    if (!this.stopped) {
      if (
        new Date(this.simulacion.steps?.[index!].end_date!).getTime() >
          new Date(this.simulacion.steps?.[index!].start_date!).getTime() +
            this.limiteDias * 24 * 60 * 60 * 1000 &&
        this.tiempoRestante.dias >= 1 &&
        !this.secundario
      ) {
        // console.log(new Date(this.simulacion.steps?.[index!].end_date!));

        const newDate =
          new Date(this.simulacion.steps?.[index!].end_date!).getTime() -
          24 * 60 * 60 * 1000;
        const paso = this.simulacion.steps?.[index!];
        paso!.end_date = new Date(newDate).getTime();
        this.simulacion.steps?.[index!] != paso!;
        this.simulationService.putSimulation(this.simulacion);

        // console.log(new Date(this.simulacion.steps?.[index!].end_date!));
      } else if (
        new Date(this.simulacion.steps?.[index!].end_date2!).getTime() >
          new Date(this.simulacion.steps?.[index!].start_date2!).getTime() +
            this.limiteDias * 24 * 60 * 60 * 1000 &&
        this.tiempoRestante.dias >= 1 &&
        this.secundario
      ) {
        // console.log(new Date(this.simulacion.steps?.[index!].end_date2!));

        const newDate =
          new Date(this.simulacion.steps?.[index!].end_date2!).getTime() -
          24 * 60 * 60 * 1000;
        const paso = this.simulacion.steps?.[index!];

        paso!.end_date2 = new Date(newDate).getTime();
        this.simulacion.steps?.[index!] != paso!;

        this.simulationService.putSimulation(this.simulacion);

        // console.log(new Date(this.simulacion.steps?.[index!].end_date2!));
      } else {
        console.log('No se puede disminuir más el tiempo');
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No se puede disminuir más el tiempo',
        });
        // console.warn(this.tiempoRestante);
      }
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalo); // Detener el intervalo de tiempo cuando el componente se destruye
  }
}
