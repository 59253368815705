import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { tap, switchMap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

import { ActionService, ProcessService, UserService, SimulationService } from 'src/app/services';
import { Action, Process, StepSimulation, Simulation, StepName } from 'src/app/models';
import { stringToSlug } from 'src/app/utils/stringToSlug';

@Component({
  selector: 'app-crear-proceso',
  templateUrl: './crear-proceso.component.html',
  styleUrls: ['./crear-proceso.component.css']
})
export class CrearProcesoComponent implements OnInit {

  @ViewChild('txtBuscar') txtBuscar!: ElementRef<HTMLInputElement>;

  actions: Action[] = [];
  processes: Process[] = [];
  steps: StepSimulation[] = []
  users: string[] = [];
  usersAux: string[] = [];
  simulation!: Simulation;

  simulationForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]],
    action: [{ value: '', disabled: this.actions.length === 0 }, [Validators.required]],
    process: ['', [Validators.required]],
    subject: ['', [Validators.required]],
    zoomAudience: ['', [Validators.required, Validators.minLength(3)]],
    juez: ['', [Validators.required]],
    ab_demandante: ['', [Validators.required]],
    ab_defensor: ['', [Validators.required]],
    secretario: ['', [Validators.required]],
  });

  constructor( public dialogRef: MatDialogRef<CrearProcesoComponent>,
               private processService: ProcessService,
               private simulationService: SimulationService,
               private actionService: ActionService,
               private userService: UserService,
               private fb: FormBuilder ) {}

  ngOnInit() {
    this.processService.getProcesses().subscribe(
      process =>
        this.processes = process
      )
    this.userService.getAllStudent().subscribe(
      usuarios => {
        this.users = usuarios.map( user => user.displayName! ).sort();
        this.usersAux = this.users;
      }
    )

    //Cargar las acciones cuando elija un procedimiento

    this.simulationForm.get('process')?.valueChanges
      .pipe(
        tap( ( _ ) => {
          this.simulationForm.get('action')?.reset('');
          this.simulationForm.get('action')?.enable();
        }),
        switchMap( process => this.actionService.getActionByProcess(process) ),
      )
      .subscribe( (actions: any) => {
        this.actions = actions;
      });

  }

  campoNoValido(campo: string) {
    return this.simulationForm.get(campo)?.invalid && this.simulationForm.get(campo)?.touched;
  }

  closeCreateSimulation() {
    this.dialogRef.close();
  }

  filtrarUsuarios($event: any) {
    let valor: string = $event.target.value.toLowerCase().trim();
    this.users = this.usersAux.sort();
    this.users = this.users.filter( usuario => usuario.toLowerCase().includes(valor) );
  }

  limpiarBuscador() {
    this.txtBuscar.nativeElement.value = '';
    this.users = this.usersAux;
  }

  get identifier() {
    const date = new Date();
    const dateParse = date.getTime().toString();
    const id = dateParse.slice(dateParse.length - 4, dateParse.length);
    const actionSelected = this.simulationForm.get('action')?.value.trim().slice(0, 3);
    const processSelected = this.simulationForm.get('process')?.value.trim().slice(0, 3);
    const nameSplit = this.simulationForm.get('name')?.value.trim().split(' ')[0];
    return `${nameSplit}-${actionSelected}-${processSelected}-${id}`;
  }

  createSimulation() {

    if( this.simulationForm.invalid ){
      this.simulationForm.markAllAsTouched();
      return;
    }

    const stepName: StepName = 'Demanda';
    this.steps.push({
      name: stepName,
      slug: stringToSlug(stepName),
      route: stringToSlug(stepName),
    })

    this.simulation = {
      ...this.simulationForm.value,
      identifier: this.identifier,
      create: new Date(),
      steps: this.steps,
      status: 'created',
    }
    this.simulationService.postSimulation(this.simulation);
    this.dialogRef.close();

  }
}
