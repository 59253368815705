import { StepName, StepSimulation } from 'src/app/models';
import { stringToSlug } from './stringToSlug';

export const createNextStep = (
  stepName: StepName,
  fechaLimite: boolean = false,
  nroDias?: number
): StepSimulation => {
  if (fechaLimite) {
    // const startDate = new Date();
    const fecha_manana = new Date();
    fecha_manana.setDate(fecha_manana.getDate() + 1);
    fecha_manana.setHours(0);
    fecha_manana.setMinutes(0);
    fecha_manana.setSeconds(0);
    // console.log('La fecha de mañana a las 00:00 es:', fecha_manana);
    const fecha_fin = new Date();
    fecha_fin.setDate(fecha_fin.getDate() + nroDias!);
    fecha_fin.setHours(23);
    fecha_fin.setMinutes(59);
    fecha_fin.setSeconds(59);
    // console.log('La fecha de fin es:', fecha_fin);

    return {
      name: stepName,
      route: stringToSlug(stepName),
      slug: stringToSlug(stepName),
      start_date: fecha_manana.getTime(),
      end_date: fecha_fin.getTime(),
    };
  } else {
    return {
      name: stepName,
      route: stringToSlug(stepName),
      slug: stringToSlug(stepName),
    };
  }
};

export const calculateStartEndDate = (
  nroDias: number
): { startDate: number | string | Date; endDate: number | string | Date } => {
  const fecha_manana = new Date();
  fecha_manana.setDate(fecha_manana.getDate() + 1);
  fecha_manana.setHours(0);
  fecha_manana.setMinutes(0);
  fecha_manana.setSeconds(0);
  // console.log('La fecha de mañana a las 00:00 es:', fecha_manana);
  const fecha_fin = new Date();
  fecha_fin.setDate(fecha_fin.getDate() + nroDias!);
  fecha_fin.setHours(23);
  fecha_fin.setMinutes(59);
  fecha_fin.setSeconds(59);
  // console.log('La fecha de fin es:', fecha_fin);

  return {
    startDate: fecha_manana.getTime(),
    endDate: fecha_fin.getTime(),
  };
};
