<main class="main-container">
    <div class="big-container">
        <h1 class="logo">STESAJ</h1>
        <div class="title-container">
            <h2 class="main-title">Soporte tecnológico educativo de simulación en administración de justicia</h2>
            <div class="gold-box"></div>
            <div class="white-box"></div>
        </div>
        <footer class="footer">
            <ul>
                <li class="link">Política de servicio</li>
                <li class="link">Términos de uso</li>
                <li class="link link-end">@UTPL 2022</li>
            </ul>
        </footer>
    </div>
    <div class="small-container">
        <div class="form-container">
            <h2 class="title">Iniciar Sesión</h2>
            <form class="form" autocomplete="off">
                <div class="form-field">
                    <label for="mail" class="label">Correo Electrónico</label>
                    <input id="mail" type="email" name="email" [(ngModel)]="email">
                </div>
                <div class="form-field">
                    <label for="password" class="label">Contraseña</label>
                    <input id="password" type="password" name="password" [(ngModel)]="password">
                </div>
                <button mat-button (click)="login()" class="btn-submit">Continuar</button>
                <button mat-button (click)="loginUtpl()" class="btn-submit">Iniciar Sesion UTPL</button>
                <div class="flex-center">
                    <p class="help">¿No tienes cuenta? <span routerLink="/register">Crea tu cuenta</span></p>
                </div>
            </form>
        </div>
    </div>
</main>