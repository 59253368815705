<div class="process-box">
    <div class="process-info" (click)="abrirProceso(simulation.identifier)">
        <p>Caso #{{ simulation.identifier }}</p>
        <p>{{ simulation.name }}</p>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="more-info">
        <mat-icon 
            class="icon-arrow" 
            aria-hidden="false" 
            aria-label="more-info">
            expand_more
        </mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="bg-919191">
        <div class="panel-info">
            <h3>Participantes:</h3>
            <ul>
                <li><b>Ab. Demandante: </b><br>
                    {{simulation.ab_demandante}} </li>
                <li><b>Ab. Defensor: </b><br>
                    {{simulation.ab_defensor}} </li>
                <li><b>Juez: </b><br>
                    {{simulation.juez}} </li>
                <li><b>Secretario: </b><br>
                    {{simulation.secretario}} </li>
            </ul>
        </div>
    </mat-menu>
</div>
