<app-header title="Proceso"></app-header>
<div class="contenedor" *ngIf="completed">
    <mat-table [dataSource]="documents" class="mat-elevation-z8">

      <ng-container matColumnDef="filename">
        <mat-header-cell *matHeaderCellDef>Documentación</mat-header-cell>
        <mat-cell *matCellDef="let document"> {{document?.fileName || 'Sin documento'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Tipo</mat-header-cell>
        <mat-cell *matCellDef="let document"> {{document.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>
        <mat-cell *matCellDef="let document"> {{document.state ? 'Entregado' : 'No Entregado'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="publication_date">
        <mat-header-cell *matHeaderCellDef>Fecha de Entrega</mat-header-cell>
        <mat-cell *matCellDef="let document"> {{ document.publication_date ? (document.publication_date | date: 'dd/MM/yyyy') : 'Sin Fecha'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="author">
        <mat-header-cell *matHeaderCellDef>Autor</mat-header-cell>
        <mat-cell *matCellDef="let document"> {{document?.author || 'Sin autor'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="revisar" *matCellDef="let document" (click)="viewFile(document.document)"> {{document.document ? 'Revisar' : 'Sin documento'}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
</div>
