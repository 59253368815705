import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';
import { UserService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private auth: AngularFireAuth,
               private router: Router,
               private userService: UserService ) {}

  canActivate(){
    return this.auth.authState.pipe(
      take(1),
      switchMap( async ( authState ) => {
        if( authState ) {
          this.userService.getUserById( authState.uid ).subscribe( user => {
            if( user ) {
              this.userService.setUser( user );
              return true;
            } else {
              this.router.navigate(['/login']);
              console.log('No existe el usuario');
              return false;
            }
          })
          return true;
        } else {
          localStorage.clear();
          this.router.navigate(['/login'])
          return false
        }
      })
    )
  }

  canLoad(){
    return this.auth.authState.pipe(
      take(1),
      switchMap( async ( authState ) => {
        if( authState ) {
          this.userService.getUserById( authState.uid ).subscribe( user => {
            if( user ) {
              this.userService.setUser( user );
              return true;
            } else {
              this.router.navigate(['/login']);
              console.log('No existe el usuario');
              return false;
            }
          })
          return true;
        } else {
          localStorage.clear();
          this.router.navigate(['/login'])
          return false
        }
      })
    )
  }
  
}
