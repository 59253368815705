import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';

import { CommitService, DocumentService, UserService } from 'src/app/services';
import { Commit, Document } from 'src/app/models';

@Component({
  selector: 'app-document-review',
  templateUrl: './document-review.component.html',
  styleUrls: ['./document-review.component.css'],
})
export class DocumentReviewComponent implements OnInit {
  private history: string[] = [];

  commits: Commit[] = [];
  body = '';
  document_url = '';
  document: Document = {
    author: '',
    author_function: '',
    publication_date: '',
    name: '',
    path: '',
  };
  rol = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private commitService: CommitService,
    private userService: UserService,
    private location: Location
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  commit() {}

  back() {
    if (this.history.length > 0) {
      this.location.back();
    } else {
      localStorage.clear();
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(switchMap(({ id }) => this.documentService.getDocumentById(id)))
      .subscribe((document) => {
        this.document = document;
      });
  }
}
