import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Simulation } from 'src/app/models';

@Component({
  selector: 'app-proccess-box',
  templateUrl: './process-box.component.html',
  styleUrls: ['./process-box.component.css']
})
export class ProcessBoxComponent {
  @Input() simulation!: Simulation;
  @Input() userRol!: string;

  constructor( private router: Router ) { }

  abrirProceso(id: string){
    switch(this.userRol){
      case "estudiante":{
        this.router.navigate([`/process/${id}`]);
        break;
      }
      case "tutor":{
        this.router.navigate([`/process-review/${id}`]);
        break;
      }
    }

  }
}

