import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Document } from 'src/app/models/document';
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor( private db: AngularFirestore ) {}

  postDocument(document: Document) {
    return this.db.collection('documents').add(document);
  }

  getDocumentById(id: string) {
    return this.db.collection('documents').doc(id).snapshotChanges().pipe(
      map( a => {
        const data = a.payload.data() as Document;
        data.id = a.payload.id;
        return data;
      })
    );
  }

}
