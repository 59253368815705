<ul class="sidenav-items">
    <li class="sidenav-item">
        <a
            class="sidenav-link"
            (click)="cerrarSidenav()"
            routerLink="./"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            Resumen del procedimiento
        </a>
    </li>
    <li *ngFor="let step of steps" class="sidenav-item">
        <a
            class="sidenav-link"
            (click)="cerrarSidenav()"
            [routerLink]="step.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            {{ step.name }}
        </a>
    </li>
</ul>
