<main class="main">
    <nav class="section-nav">
        <button mat-icon-button aria-label="back" (click)="back()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <h2 class="title">Comentarios</h2>
    </nav>
    <div class="section-file">
        <div class="view-file">
            <ngx-doc-viewer [url]="document.path" viewer="google" style="width:100%;height:100%;"></ngx-doc-viewer>
        </div>
        <div *ngIf="commits.length > 0" class="column-comentary">
            <div class="comentary">
                <div *ngFor="let commit of commits">
                    <p class="autor">{{commit.autor}}</p>
                    <p class="commit">{{commit.body}}</p>
                    <div class="vl"></div>
                </div>
            </div>

            <mat-form-field  *ngIf="rol=='tutor'" appearance="fill">
                <mat-label></mat-label>
                <textarea  class="text-area" matInput [(ngModel)]="body"></textarea>

            </mat-form-field>
            <button *ngIf="rol=='tutor'" class="btn-commit" (click)="commit()" mat-button>COMENTAR</button>
        </div>
    </div>
</main>