import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent {

  @Output() addFile = new EventEmitter<File>();
  files: File[] = []

  constructor() { }

  onFileSelected(event: any) {
    this.files = event.target.files;
    if (this.files) {
      this.addFile.emit(this.files[0])
    }
  }

}
