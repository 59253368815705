import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Simulation } from '../models/simulation';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  constructor( private db: AngularFirestore ) {}

  getSimulations() {
    return this.db.collection('simulation').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Simulation;
        data.id = a.payload.doc.id;
        return data;
      }))
    );
  }

  getSimulationByIdentifier(identifier: string) {
    const simulationCollection = this.db.collection('simulation', ref => ref.where("identifier", "==", identifier))
    return simulationCollection.snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Simulation;
          data.id = a.payload.doc.id;
          return data;
        }))
      );
  }

  getSimulation(identifier: string) {
    const simulationCollection = this.db.collection('simulation', ref => ref.where("identifier", "==", identifier))
    return simulationCollection.get()
      .pipe(
        map(a => {
          const data = a.docs[0].data() as Simulation;
          data.id = a.docs[0].id;
          return data;
        })
      );
  }

  getSimulationByUser(userName: string) {
    const $juez = this.db.collection("simulation", ref => ref.where('juez', '==' , userName)).valueChanges();
    const $ab_demandante = this.db.collection("simulation", ref => ref.where("ab_demandante",'==', userName)).valueChanges();
    const $ab_defensor = this.db.collection("simulation", ref => ref.where("ab_defensor",'==', userName)).valueChanges();
    const $secretario = this.db.collection("simulation", ref => ref.where("secretario",'==', userName)).valueChanges();
    return combineLatest($juez, $ab_demandante, $ab_defensor, $secretario)
      .pipe(
        map(([juez, ab_demandante, ab_defensor, secretario]) => [...juez, ...ab_demandante, ...ab_defensor, ...secretario])
      )
  }

  putSimulation(simulation: Simulation) {
    this.db.doc(`simulation/${simulation.id}`).update(simulation);
  }

  postSimulation(simulation: Simulation) {
    this.db.collection('simulation').add(simulation)
    .then(result => {
      Swal.fire({
        title: 'Simulación Creada',
        text: 'Simulación creada con éxito!',
        icon: 'success'
      })
    })
    .catch(err => {
      Swal.fire({
        title: 'Error',
        text: err,
        icon: 'error'
      })
    })
  }

}
