import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StepSimulation } from 'src/app/models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {

  @Input() steps: StepSimulation[] = [];
  @Output() onCloseSidenav: EventEmitter<any> = new EventEmitter();

  cerrarSidenav() {
    this.onCloseSidenav.emit();
  }

}
