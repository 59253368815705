import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private afAuth: AngularFireAuth,
               private firestore: AngularFirestore,
               private router: Router ) {}

  register( email: string, password: string ) {
    return this.afAuth.createUserWithEmailAndPassword( email, password );
  }

  loginEmailPassword( email: string, password: string ) {
    return this.afAuth.signInWithEmailAndPassword( email, password );
  }
              
  loginMicrosoft() {
    const microsoftAuthProvider = new firebase.auth.OAuthProvider("microsoft.com");

    microsoftAuthProvider.setCustomParameters({
      client_id: "7e4d31b1-d0b5-4aab-897c-05f665d604ae",
      tenant: "6eeb49aa-436d-43e6-becd-bbdf79e5077d",
      redirect_uri: "http://localhost:4200/home",
    });
    microsoftAuthProvider.addScope("user.read");
    microsoftAuthProvider.addScope("openid");
    microsoftAuthProvider.addScope("profile");
    microsoftAuthProvider.addScope("mail.send");
    this.loginProvider(microsoftAuthProvider);
  }

  async loginProvider( provider: any ) {
    return this.afAuth.signInWithPopup( provider )
      .then( (response: any) => {
        console.log(response);
        if(response.additionalUserInfo?.isNewUser === true) {

          const usuario: User = {
            id: response.user?.uid,
            mail: response.additionalUserInfo.profile?.userPrincipalName,
            displayName: response.additionalUserInfo.profile?.displayName,
            givenName: response.additionalUserInfo.profile?.givenName,
            surname: response.additionalUserInfo.profile?.surname!,
            photo_url: response.user?.photoURL!,
            role: 'estudiante',
          };
          this.agregarUsuario( usuario! );
          this.router.navigateByUrl('/home');

        } else {
          const { redirect } = window.history.state;
          this.router.navigateByUrl( redirect || '/home');
        }

      })
      .catch( err => {
        console.log(err);
      })
  }

  async logout() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
    await this.afAuth.signOut();
  }

  agregarUsuario( usuario: User ) {
    return this.firestore.collection('users').doc( usuario.id ).set( usuario );
  }
}
