import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService, ValidatorService } from 'src/app/services';
import { User } from 'src/app/models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  usuario!: User;

  registerForm: FormGroup = this.fb.group({
    givenName: [ '', Validators.required ],
    surname: [ '', Validators.required ],
    mail: [ '', [Validators.required, Validators.email] ],
    password: ['', [Validators.required, Validators.minLength(6)] ],
    password2: ['', [Validators.required] ],
  }, {
    validators: [ this.validator.camposIguales( 'password', 'password2' ) ]
  })
  
  constructor( private fb: FormBuilder,
               private authService: AuthService,
               private router: Router,
               private validator: ValidatorService
  ) { }

  register() {

    const { noIguales } = this.registerForm.errors || { noIguales: false };
    if( noIguales ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Las contraseñas no coinciden'
      })
      return;
    }

    if( this.registerForm.invalid ) {
      Swal.fire({
        icon: 'error',
        title: 'Campos Obligatorios',
        text: 'Complete todos los campos del registro',
      })
      return;
    }

    const givenName = this.registerForm.get('givenName')?.value
    const surname = this.registerForm.get('surname')?.value
    this.usuario = {
      givenName: givenName,
      surname: surname,
      displayName: `${givenName} ${surname}`,
      mail: this.registerForm.get('mail')?.value,
      role: 'estudiante',
    }; 

    const email = this.registerForm.value.mail;; 
    const password = this.registerForm.value.password;

    this.authService.register( email, password )
      .then( (userCredential: any)  => {
        this.usuario.id = userCredential.user.uid;
        this.authService.agregarUsuario( this.usuario ).then( () => {
          this.router.navigateByUrl('/play');
        }).catch( error => {
          console.log('Error al agregar el usuario:', error);
        });
      })
      .catch( error => {
        if( error.code === 'auth/email-already-in-use' ) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'El correo ya está en uso',
          })
          return;
        }
      })
      
    
  }

}
