import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() title: string = '';
  @Input() info: string = '';

  private history: string[]= []

  constructor( private router: Router,
               private location: Location ) {
    router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  back(){
    if(this.history.length > 0){
      this.location.back()
    } else {
      this.router.navigateByUrl('/home')
    }
  }

}
