<div class="choose">
  <mat-icon class="icon-cloud" aria-hidden="false" aria-label="choose-document">
    cloud_upload
  </mat-icon>
  <input
    type="file"
    class="select-file"
    accept=".pdf,.docx,.doc"
    (change)="onFileSelected($event)"
  />
</div>
