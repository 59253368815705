import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DocumentService } from './document.service';
import { SimulationService } from './simulation.service';

import { Document, UploadFile } from 'src/app/models';
import { createNextStep } from 'src/app/utils/createNextStep';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  basePath: string = 'documents';
  document: Document = {
    author: '',
    author_function: '',
    publication_date: 0,
    name: '',
    path: '',
  };
  documentCollection!: AngularFirestoreCollection<Document>;
  docDocument!: Observable<Document[]>;

  constructor(
    private documentService: DocumentService,
    private storage: AngularFireStorage,
    private simulationService: SimulationService
  ) {}

  postFile(newStep: UploadFile, fechaLimite?: boolean, nroDias?: number) {
    const id = new Date().getTime();
    const fileName = `${id}${newStep.file.name}`;

    const filePath = `${this.basePath}/${newStep.simulationIdentifier}/${fileName}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, newStep.file);

    Swal.fire({
      title: 'Cargando archivo...',
      text: 'Por favor espere...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(async (downloadURL) => {
            this.document.path = downloadURL;
            this.document.name = newStep.file.name;
            this.document.author = newStep.user;
            this.document.author_function = newStep.userFunction;
            this.document.publication_date = Date.now();
            const { id: idDocument } = await this.documentService.postDocument(
              this.document
            );

            this.documentService
              .getDocumentById(idDocument)
              .subscribe((document) => {
                this.simulationService
                  .getSimulation(newStep.simulationIdentifier)
                  .subscribe((result) => {
                    result.steps?.map((step) => {
                      if (step.name === newStep.step) {
                        step.document = document.id; // agregar llave del documento
                        step.stop_date = Date.now();
                      }
                    });
                    if (newStep.nextStep) {
                      const nextStep = createNextStep(
                        newStep.nextStep,
                        fechaLimite,
                        nroDias
                      );
                      result.steps?.push(nextStep);
                    }
                    this.simulationService.putSimulation(result);
                    Swal.close();
                    Swal.fire({
                      title: '¡Listo!',
                      text: 'El documento se subió correctamente',
                      icon: 'success',
                    });
                  });
              });
          });
        })
      )
      .subscribe();
    return uploadTask.percentageChanges();
  }

  postFile2(newStep: UploadFile, fechaLimite?: boolean, nroDias?: number) {
    Swal.fire({
      title: 'Cargando archivo...',
      text: 'Por favor espere...',
      icon: 'info',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const filePath = `${this.basePath}/${newStep.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, newStep.file);

    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(async (downloadURL) => {
            this.document.path = downloadURL;
            this.document.name = newStep.file.name;
            this.document.author = newStep.user;
            this.document.author_function = newStep.userFunction;
            this.document.publication_date = Date.now();
            const { id: idDocument } = await this.documentService.postDocument(
              this.document
            );

            this.documentService
              .getDocumentById(idDocument)
              .subscribe((document) => {
                this.simulationService
                  .getSimulation(newStep.simulationIdentifier)
                  .subscribe((result) => {
                    result.steps?.map((step) => {
                      if (step.name === newStep.step) {
                        step.document2 = document.id; // agregar llave del documento 2
                        step.stop_date2 = Date.now();
                      }
                    });
                    if (newStep.nextStep) {
                      const nextStep = createNextStep(
                        newStep.nextStep,
                        fechaLimite,
                        nroDias
                      );
                      result.steps?.push(nextStep);
                    }
                    this.simulationService.putSimulation(result);
                    Swal.close();
                    Swal.fire({
                      title: '¡Listo!',
                      text: 'El documento se subió correctamente',
                      icon: 'success',
                    });
                  });
              });
          });
        })
      )
      .subscribe();
    return uploadTask.percentageChanges();
  }
}
