<header>
    <nav class="navbar">
        <div class="navbar-section">
            <button mat-icon-button aria-label="back" (click)="back()">
              <mat-icon class="icon-arrow">play_arrow</mat-icon>
            </button>
            <h1 class="navbar-title">{{title}}</h1>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="icon-info" aria-hidden="false" aria-label="info">info</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="bg-919191">
            <div class="panel-info">
                <p>{{info}}</p>
            </div>
        </mat-menu>
    </nav>
</header>
