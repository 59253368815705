import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user$: BehaviorSubject<User> = new BehaviorSubject({} as User);;
  private _userHome$: Subject<User> = new Subject();

  constructor( private db: AngularFirestore ) {}

  setUser(user: User) {
    this._user$.next(user);
    this._userHome$.next(user);
  }
  
  get user$() {
    return this._user$.asObservable();
  }

  get userHome$() {
    return this._userHome$.asObservable();
  }

  getAllStudent(){
    return this.db.collection('users', ref => ref.where("role", "==", 'estudiante')).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as User;
        data.id = a.payload.doc.id;
        return data;
      }))
    );
  }

  getUserById(id: string){
    return this.db.collection('users').doc(id).snapshotChanges().pipe(
      map(a=>{
        const data = a.payload.data() as User;
        data.id = a.payload.id;
        return data;
      })
    )
  }

}
