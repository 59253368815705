<div class="contenedor" *ngIf="!disabled && !fueraHorario">
  <div class="numbers">
    <ul class="time-countdown">
      <li class="count-bg-one radius"><span id="days">{{tiempoRestante.dias}}</span>Dias</li>
      <li class="count-bg-two radius"><span id="hours">{{tiempoRestante.horas}}</span>Horas</li>
      <li class="count-bg-three radius"><span id="minutes">{{tiempoRestante.minutos}}</span>Minutos</li>
      <li class="count-bg-four radius"><span id="seconds">{{tiempoRestante.segundos}}</span>Segundos</li>
    </ul>
  </div>

  <!-- <pre>juez {{juez}}</pre> -->
  <!-- <pre>!stopped {{!stopped}}</pre> -->
  <!-- <pre>fueraDeHorario {{fueraHorario}}</pre> -->
  <div class="contador" *ngIf="juez && !stopped">
    <button (click)="aumentarTiempo()">+ 1 día</button>
    <button (click)="disminuirTiempo()">- 1 día</button>
  </div>
</div>

<div class="" *ngIf="fueraHorario">
  <p>Se encuentre fuera de horario laboral ({{horarioDisponibilidad.inicio}}:00 - {{horarioDisponibilidad.fin-1}}:59)
    del
    día de mañana</p>
</div>
