import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Action } from 'src/app/models/action';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor( private db: AngularFirestore ) {}

   getActionById(id: string){
     return this.db.collection('actions').doc(id).snapshotChanges().pipe(
       map(a => {
         const data = a.payload.data() as Action;
         data.id = a.payload.id;
         return data;
       })
     );
   }

   getActionByProcess(process: string){
      return this.db.collection('actions', ref => ref.where("process","==",process)).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Action;
          data.id = a.payload.doc.id;
          return data;
        }))
      )
   }

}
