<h1 class="mat-dialog-title">Crear Proceso</h1>
<form 
  [formGroup]="simulationForm" 
  class="mat-dialog-content"
  autocomplete="off">
  <section class="section-form">
    <mat-form-field class="form-input" appearance="legacy">
      <mat-label>Nombre del Proceso</mat-label>
      <input matInput type="text" formControlName="name">
      <mat-error *ngIf="campoNoValido('name')">
        El nombre del proceso es requerido
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input" appearance="legacy">
      <mat-label>Materia</mat-label>
      <input matInput type="text" formControlName="subject">
      <mat-error *ngIf="campoNoValido('subject')">
        La materia es requerida
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input" appearance="legacy">
      <mat-label>Procedimiento</mat-label>
      <mat-select name="Procedimiento" formControlName="process">
        <mat-option 
          *ngFor="let process of processes" 
          [value]="process.id" 
          [disabled]="!process.state"> 
          {{ process.name }} 
        </mat-option>
      </mat-select>
      <mat-icon class="icon-arrow" matSuffix>play_arrow</mat-icon>
      <mat-error *ngIf="campoNoValido('process')">
        El tipo de procedimiento es requerido
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input" appearance="legacy">
      <mat-label>Audiencia Zoom</mat-label>
      <input matInput type="text" formControlName="zoomAudience">
      <mat-error *ngIf="campoNoValido('zoomAudience')">
        El link de zoom es requerido
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input" appearance="legacy">
      <mat-label>Tipo de Acción</mat-label>
      <mat-select class="type-accion" formControlName="action">
        <mat-option *ngFor="let action of actions" [value]="action.id">
          {{action.name}}
        </mat-option>
      </mat-select>
      <mat-icon class="icon-arrow" matSuffix>play_arrow</mat-icon>
      <mat-hint>Debe elegir un tipo de procedimiento primero</mat-hint>
      <mat-error *ngIf="campoNoValido('action')">
        El tipo de acción es requerido
      </mat-error>
    </mat-form-field>

  </section>
  <section class="section-actors">

    <!-- Esquinas de la sección de actores -->
    <div class="corner-top-left"></div>
    <div class="corner-top-right"></div>
    <div class="corner-bottom-left"></div>
    <div class="corner-bottom-right"></div>

    <div class="flex-center">
      <div class="title-actors">
        <h2>Actores</h2>
        <div class="box-gold"></div>
        <div class="box-white"></div>
      </div>
    </div>

    <div class="actor">
      <h2 class="actor-title">Juez</h2>
      <mat-form-field class="actor-input">
        <mat-select
            formControlName="juez"
            (closed)="limpiarBuscador()">
            <mat-form-field class="select-input">
                <mat-label>Buscador</mat-label>
                <form autocomplete="off">
                    <input
                        #txtBuscar
                        matInput 
                        type="text" 
                        placeholder="Buscar Usuario" 
                        (keyup)="filtrarUsuarios($event)">
                </form>
            </mat-form-field>
            <mat-option *ngFor="let user of users" [value]="user">
                {{ user }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="campoNoValido('juez')">
          Debe elegir un juez
        </mat-error>
      </mat-form-field>
    </div>

    <div class="actor">
      <h2 class="actor-title">Abogado Demandante</h2>
      <mat-form-field class="actor-input">
        <mat-select
            formControlName="ab_demandante"
            (closed)="limpiarBuscador()">
            <mat-form-field class="select-input">
                <mat-label>Buscador</mat-label>
                <form autocomplete="off">
                    <input
                        #txtBuscar
                        matInput type="text" 
                        placeholder="Buscar Usuario" 
                        (keyup)="filtrarUsuarios($event)">
                </form>
            </mat-form-field>
            <mat-option *ngFor="let user of users" [value]="user">
                {{ user }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="campoNoValido('ab_demandante')">
          Debe elegir un abogado demandante
        </mat-error>
      </mat-form-field>
    </div>

    <div class="actor">
      <h2 class="actor-title">Abogado Defensor</h2>
      <mat-form-field class="actor-input">
        <mat-select
            formControlName="ab_defensor"
            (closed)="limpiarBuscador()">
            <mat-form-field class="select-input">
                <mat-label>Buscador</mat-label>
                <form autocomplete="off">
                    <input
                        #txtBuscar
                        matInput type="text" 
                        placeholder="Buscar Usuario" 
                        (keyup)="filtrarUsuarios($event)">
                </form>
            </mat-form-field>
            <mat-option *ngFor="let user of users" [value]="user">
                {{ user }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="campoNoValido('ab_defensor')">
          Debe elegir un abogado defensor
        </mat-error>
      </mat-form-field>
    </div>

    <div class="actor">
      <h2 class="actor-title">Secretario</h2>
      <mat-form-field class="actor-input">
        <mat-select
            formControlName="secretario"
            (closed)="limpiarBuscador()">
            <mat-form-field class="select-input">
                <mat-label>Buscador</mat-label>
                <form autocomplete="off">
                    <input
                        #txtBuscar
                        matInput 
                        type="text" 
                        placeholder="Buscar Usuario" 
                        (keyup)="filtrarUsuarios($event)">
                </form>
            </mat-form-field>
            <mat-option *ngFor="let user of users" [value]="user">
                {{ user }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="campoNoValido('secretario')">
          Debe elegir un secretario
        </mat-error>
      </mat-form-field>
    </div>
  </section>
</form>
<div class="mat-dialog-actions">
  <button (click)="closeCreateSimulation()">Cancelar</button>
  <button (click)="createSimulation()">Crear</button>
</div>