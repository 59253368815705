import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Process } from '../models/process';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  constructor( private db: AngularFirestore ){}

  getProcesses() {
    return this.db.collection<Process>('process').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Process;
        data.id = a.payload.doc.id;
        return data;
      }))
    )
  }

  getProcessById(id: string){
    return this.db.collection('process').doc(id).snapshotChanges().pipe(
      map( a => {
        const data = a.payload.data() as Process;
        data.id = a.payload.id;
        return data;
      })
    )
  }

}
