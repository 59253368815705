<main class="main-container">
    <div class="big-container">
        <h1 class="logo">STESAJ</h1>
        <div class="title-container">
            <h2 class="main-title">Soporte tecnológico educativo de simulación en administración de justicia</h2>
            <div class="gold-box"></div>
            <div class="white-box"></div>
        </div>
        <footer class="footer">
            <ul>
                <li class="link">Política de servicio</li>
                <li class="link">Términos de uso</li>
                <li class="link link-end">@UTPL 2022</li>
            </ul>
        </footer>
    </div>
    <div class="small-container">
        <div class="form-container">
            <h2 class="title">Registrarse</h2>
            <form class="form" autocomplete="off" [formGroup]="registerForm">
                <div class="form-field">
                    <label for="givenName" class="label">Nombres</label>
                    <input id="givenName" type="text" formControlName="givenName">
                </div>
                <div class="form-field">
                    <label for="surname" class="label">Apellidos</label>
                    <input id="surname" type="text" formControlName="surname">
                </div>
                <div class="form-field">
                    <label for="mail" class="label">Correo Electrónico</label>
                    <input id="mail" type="email" formControlName="mail">
                </div>
                <div class="form-field">
                    <label for="password" class="label">Contraseña</label>
                    <input id="password" type="password" formControlName="password">
                </div>
                <div class="form-field">
                    <label for="password2" class="label">Confirmar Contraseña</label>
                    <input id="password2" type="password" formControlName="password2">
                </div>
                <button mat-button (click)="register()" class="btn-submit">Continuar</button>
                <div class="flex-center">
                    <p class="help">¿Ya tienes una cuenta? <span routerLink="/login">Inicia Sesión</span></p>
                </div>
            </form>
        </div>
    </div>
</main>
