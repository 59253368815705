// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBWWnCNtSTxMfyUWOtdeietRQHJPzkQk0Q",
    authDomain: "justice-simulator.firebaseapp.com",
    projectId: "justice-simulator",
    storageBucket: "justice-simulator.appspot.com",
    messagingSenderId: "520387685304",
    appId: "1:520387685304:web:ad1b189c77139505283d4d",
    measurementId: "G-17BP1VTV5D"
  },
  orderSteps: [
    "Demanda",
    "Calificacion de la demanda",
    "Contestacion de la demanda",
    "Calificación de la contestación de la demanda",
    "Reconvención",
    "Calificación de la reconvención",
    "Audiencia",
    "Resolución",
    "Apelación"
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
