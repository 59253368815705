import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { ComponentsModule } from './components/components.module';

// Firebase
import { AngularFirestoreModule } from '@angular/fire/firestore'
import { environment } from '../environments/environment'
import { AngularFireModule } from '@angular/fire'

import { AppComponent } from './app.component';
import { DocumentReviewComponent } from './pages/document-review/document-review.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProcessReviewComponent } from './pages/process-review/process-review.component';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    DocumentReviewComponent,
    HomeComponent,
    LoginComponent,
    LayoutComponent,
    RegisterComponent,
    ProcessReviewComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxDocViewerModule,
    ReactiveFormsModule,
    ComponentsModule,
    //firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
